"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import classNames from "classnames";

import { isActivePath } from "@/utils/isActivePath";
import {
  AcademicCapIcon,
  BookOpenIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";

export const BottomMenu = () => (
  <nav className="fixed bottom-0 w-full bg-white border-t border-gray-200">
    <div className="max-w-md mx-auto flex justify-around">
      <NavLink
        label="Courses"
        href="/courses"
        icon={<BookOpenIcon className="w-6 h-6" />}
      />
      <NavLink
        label="Results"
        href="/results"
        icon={<AcademicCapIcon className="w-6 h-6" />}
      />
      <NavLink
        label="Support"
        href="/support"
        icon={<QuestionMarkCircleIcon className="w-6 h-6" />}
      />
      <NavLink
        label="Profile"
        href="/profile"
        icon={<UserCircleIcon className="w-6 h-6" />}
      />
    </div>
  </nav>
);

const NavLink = ({
  href,
  label,
  icon,
}: {
  href: string;
  label: string;
  icon: React.ReactNode;
}) => {
  const pathname = usePathname();

  return (
    <Link
      className={classNames("py-2 flex flex-col items-center", {
        "text-blue-600": isActivePath(pathname, href),
      })}
      href={href}
    >
      {icon}
      <span className="text-xs">{label}</span>
    </Link>
  );
};
